import { $, $$, addEventListenerAll } from './helper/utils';
import outline from './helper/outline';
// import inView from './helper/inView';
import { MOBILMEDIA } from './helper/constants';

// const loaders = $$('.loader');
const sliderMap = new Map();
const sliderDragging = new Map();

// for (const loader of loaders) {
//   const loaderEl = document.createElement('span');
//   loaderEl.className = 'loading-indicator';
//   loader.appendChild(loaderEl);
//   loader.classList.remove('loader');
// }

const titles = $$('h2, h3');
for (const title of titles) {
  const styles = window.getComputedStyle(title);
  if (styles.textAlign === 'center') {
    title.classList.add('tc');
  }
}

outline();

const newsTeaser = $$('.news-teaser-group .floating-card-image');
for (const teaser of newsTeaser) {
  if (teaser.querySelector('a') === null) continue;

  const observer = new ResizeObserver(([entry]) => {
    console.log(entry);
    if (entry.contentBoxSize) {
      // Firefox implements `contentBoxSize` as a single content rect, rather than an array
      const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
      entry.target.parentElement.style = `--floating-card-height: ${contentBoxSize.blockSize}px`;
    } else {
      entry.target.parentElement.style = `--floating-card-height: ${entry.contentRect.height}px`;
    }
  });

  observer.observe(teaser);
}

addEventListenerAll($$('.main-nav .nav-link:not(:only-child), .group-switch > button'), 'click', function (e) {
  e.preventDefault();
  const elements = $$('.main-subnav.show, .group-selection-list.show');
  for (const visibleEl of elements) {
    visibleEl.classList.remove('show');
  }
  this.nextElementSibling.classList.toggle('show');
});

window.addEventListener('click', (e) => {
  const elements = $$('.main-subnav.show, .group-selection-list.show');
  if (elements.length === 0) {
    return;
  }

  if (e.target.closest('.main-nav-item') === null && e.target.closest('.group-switch') === null) {
    for (const visibleEl of elements) {
      visibleEl.classList.remove('show');
    }
  }
});

export default function () {
  let mobileNavLoaded = false;

  const loadMobileNav = () => {
    if ($('.mobile-nav') !== null) {
      import('./components/offcanvas')
        .then((offcanvas) => {
          mobileNavLoaded = true;
          const mobileNav = $('.mobile-nav');
          const showMenuBtn = $('.show-menu');
          const mobileMenu = offcanvas.default(mobileNav, showMenuBtn, 'top');

          showMenuBtn.addEventListener('click', mobileMenu.toggle, { passive: true });
        }).catch((err) => {
          console.warn(`Chunk offcanvas.js loading failed: ${err}`);
        });
    } else {
      mobileNavLoaded = true;
    }
  };
  import('./components/menu')
    .then((menu) => menu.default())
    .catch((err) => {
      console.warn('Chunk forms/form.js loading failed', err);
    });

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadMobileNav();
    }
  });

  if (MOBILMEDIA.matches) {
    loadMobileNav();
  }

  if ($('.jobs-wrapper') !== null || $('iframe') !== null) {
    import('./components/job-loader')
      .then((jobLoader) => jobLoader.default())
      .catch((err) => {
        console.warn('Chunk job-loader.js loading failed', err);
      });
  }

  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('picture[data-link-image-url]') !== null) {
    import('./components/linkFromPicture')
      .catch((err) => {
        console.warn('Chunk linkFromPicture.js loading failed', err);
      });
  }

  if ($('.form-group > .form-line') !== null) {
    import('./components/forms/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk forms/form.js loading failed', err);
      });
  }

  if ($('.filter-wrapper') !== null) {
    import('./components/forms/elementFilter')
      .catch((err) => {
        console.warn('Chunk forms/elementFilter.js loading failed', err);
      });
  }

  if ($('[data-ytvideo]') !== null) {
    import('./components/ytModal')
      .catch((err) => {
        console.warn('Chunk ytModal.js loading failed', err);
      });
  }

  // inView($$('.lightbox'), () => {
  //   import('./components/lightbox')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }, true);

  if ($('.history') !== null) {
    import('./components/history')
      .catch((err) => {
        console.warn('Chunk history.js loading failed', err);
      });
  }

  if ($('form[data-checkform]') !== null) {
    import('./components/check_form')
      .then((module) => {
        module.default();
      })
      .catch((err) => {
        console.warn('Chunk check_form.js loading failed', err);
      });
  }

  if ($('.shop-teaser') !== null) {
    import('./components/image_move')
      .then((module) => {
        module.default();
      })
      .catch((err) => {
        console.warn('Chunk image_move.js loading failed', err);
      });
  }

  // if ($('.specialbox') !== null) {
  //   import('./components/easyLightBox')
  //     .then((module) => {
  //       module.default();
  //     })
  //     .catch((err) => {
  //       console.warn('Chunk easyLightBox.js loading failed', err);
  //     });
  // }

  // if ($('.location-map') !== null) {
  //   console.log('test');
  //   import('./components/map')
  //     .then((module) => {
  //       module.default();
  //     })
  //     .catch((err) => {
  //       console.warn('Chunk map.js loading failed', err);
  //     });
  // }

  if ($$('.nav-tabs, .nav-tabs-vertical, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });
    };

    const collapseMap = new Map();
    const showsMore = new Set();
    const toggleText = function () {
      if (showsMore.has(this)) {
        this.textContent = collapseMap.get(this).more;
        showsMore.delete(this);
      } else {
        this.textContent = collapseMap.get(this).less;
        showsMore.add(this);
      }
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        const tabs = $$('.nav-tabs .tab-link, .nav-tabs-vertical .tab-link');
        console.log(tabs);
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }

        // toggle show more button text
        const showHide = $$('[data-showless]');
        for (let i = 0; i < showHide.length; i++) {
          const el = showHide[i];

          collapseMap.set(el, {
            more: el.textContent,
            less: el.dataset.showless,
          });
          el.addEventListener('click', toggleText);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
